<template>

    <div>
        <div>
        <Header ></Header>
        </div>
        <div style="border-radius:0">
            <slot></slot>
        </div>
    </div>
    <phone v-if="showSipClient==true"/>
</template>

<script setup>
const { $parseJwt , $get } = useNuxtApp()
// const moh = useMoh();
// const ivrs = useIVRsTemplates();



const showSipClient = useShowSipClient(); //ref(false)
const agent_id = ref(null)

if (process.client){
    let token = sessionStorage.getItem("token");
    let parsedToken = $parseJwt(token);
    let account_id = sessionStorage.getItem('account_id')
    for (let item of parsedToken.membership){
        if (item.account_id == account_id){
            if ("agent_id" in item) {
                agent_id.value = item.agent_id;
                showSipClient.value = true;
            }
        }
    }

    // const createNotification = (message) => {
    //     const notification = new Notification(message);
    // // Do something with the notification if needed
    // };

    let clientUid;
    if (sessionStorage.getItem('clientUid')) {
    clientUid = sessionStorage.getItem('clientUid');
    } else {
    // Generate a unique identifier for this tab
    clientUid = Date.now().toString(36) + Math.random().toString(36).substring(2);
    sessionStorage.setItem('clientUid', clientUid);
    }


    

}

// const notify = () => {
//     createNotification("Incoming call", "Open me", "");
// }

// // Function to create notification
// const createNotification = (title, body, url) => {
//   const options = {
//     body: body,
//     data: {
//       url: "http://localhost:3000/",
//     },
//     // actions: [
//     //     {action: "answer", icon:"", title:"Answer"}
//     // ]
//   };
//   return new Notification (title, options)
// //   return self.registration.showNotification(title, options);
// };

// Function to handle notification click
// const handleNotificationClick = (event) => {
//   const url = event.notification.data.url;
//   event.notification.close(); // Close the notification

//   // Open the URL in a new tab
//   event.waitUntil(
//     clients.openWindow(url)
//   );
// };


// Failed to construct 'Notification': Actions are only supported for persistent notifications shown using ServiceWorkerRegistration.showNotification().
//     at createNotification (protected.vue:66:10)
//     at notify (protected.vue:52:5)
//     at callWithErrorHandling (chunk-YIB7QTYA.js?v=5181f943:1374:19)
//     at callWithAsyncErrorHandling (chunk-YIB7QTYA.js?v=5181f943:1381:17)
//     at HTMLButtonElement.invoker (chunk-YIB7QTYA.js?v=5181f943:10067:5)


onMounted(async() =>  {
//     if (process.client){
//         if (("Notification" in window) 
//          if (Notification.permission === "granted") {
//             // Check whether notification permissions have already been granted;
//             // if so, create a notification
            
//             // alert("Hi there2!")
// //             new Notification('Hey', { body: 'Hello, world!', icon: '', data: {
// //             url: 'https://example.com/page',
// //           }, 
// // });
//         } else if (Notification.permission !== "denied") {
//             // We need to ask the user for permission
//             Notification.requestPermission().then((permission) => {
//             // If the user accepts, let's create a notification
//             if (permission === "granted") {
            
//     //             new Notification('Hey', { body: 'Hello, world!', icon: '', data: {
//     // url: 'https://example.com/page'} });
//                 // console.log("Hi there!")
//             }
//             });
//         }

//         // // Getting templates for ivr. Cached in localStorage
//         // let templatesUrl = '/api/protected/templates/';
//         // let templates = [];
//         // let cached_templates = localStorage.getItem(`scc_${templatesUrl}`);
//         // if (cached_templates == null){
//         //     let { data, pending, error } = await useCustomFetch(templatesUrl, {
//         //         method: 'GET',
//         //     });
//         //     templates = data.value.data;
//         //     localStorage.setItem(`scc_${templatesUrl}`,JSON.stringify(templates));
//         // } else {
//         //     templates = JSON.parse(cached_templates);
//         // }
        
//         // for (let item of templates ){
//         //     ivrs.value[item.type] = item; 
//         // }


//         // // Getting templates for ivr. Cached in localStorage
//         // let mohUrl = '/api/protected/moh';
//         // let mohData = [];
//         // let cached_moh = localStorage.getItem(`scc_${mohUrl}`);
//         // if (cached_moh == null){
//         //     let { data, pending, error } = await useCustomFetch(mohUrl, {
//         //         method: 'GET',
//         //     });
//         //     mohData = data.value.data;
//         //     localStorage.setItem(`scc_${mohUrl}`,JSON.stringify(templates));
//         // } else {
//         //     mohData = JSON.parse(cached_moh);
//         // }
        
//         // moh.value = mohData;





//         // $get(`/api/protected/templates/`)

//         // .then(response => {
            
//         // });

//         // $get(`/api/protected/moh`)
//         // .then(response => {
//         //     moh.value = response.data;
//         // });

//     }


});


// setTimeout(()=>{
//     // createNotification("Hi there!");
//     const notification = new Notification("message");
// }, 3000);

//     // if ('serviceWorker' in navigator) {
//     //   navigator.serviceWorker.register('/sw.js')
//     //     .then(registration => {
//     //       console.log('Service Worker registered with scope:', registration.scope);
//     //     })
//     //     .catch(error => {
//     //       console.error('Service Worker registration failed:', error);
//     //     });
//     // }

//     if (Notification.permission === 'default') {
//       Notification.requestPermission().then(permission => {
//         if (permission === 'granted') {
//           console.log('Notification permission granted');
//         } else {
//             console.log("BI2")    
//         }
//       });
//     } else {
//         console.log("BI")
//     }
// });


</script>

<style>
.min-width-div {
    min-width: 300px; /* Adjust the value as needed */
    overflow-x: auto; /* Add this to enable horizontal scrollbar */
    /* border: 1px solid #ffffff; Optional: Add border for better visibility */
    padding: 10px; /* Optional: Add padding for better visual appearance */
  }
  .header-container {
    z-index: 1030;
    border-bottom: 1px solid #004ed0;
}
</style>
