<template>
    <div class="btn-group-toggle row mt-5 mb-4 mx-0">
        <div v-if="agentStatus == 'Logged Out' || agentStatus == 'On Break'" class="text-color radio-primary col-12 ps-0 mb-3 custom-control custom-radio">
            <input type="radio" id=login class="custom-control-input" @click="cc_login" :disabled="props.disabled" >
            <label class="custom-control-label" :style="props.disabled ==true ? 'background-color: #b1b1b154;cursor:not-allowed' : 'background-color: #00964954;'"  for="login">Enter Call Center Mode</label>
            <p class="text-center">
                <small  v-if="props.disabled == true">You have to be online to log In to the Hotline</small>
            </p>
        </div>
        <div v-if="agentStatus == 'Available'" class="text-color radio-primary col-12 ps-0 mb-3 custom-control custom-radio">
            <input type="radio" id="logout"  class="custom-control-input" @click="cc_logout" >
            <label class="custom-control-label" style="background-color: #ff49002e;" for="logout">Exit Call Center Mode</label>
        </div>
    </div>
    
</template>

<script setup>
    const {$patch} = useNuxtApp();
    const agentStatus = useAgentPersonalStatus();

    const props = defineProps({
        disabled: { type: Boolean, default: false },

        // onClick: Function
    });

    const user = useMyData();
    // onMounted( () => {
        // if (!agentStatus.value) agentStatus.value = 'Logged Out';
    // });


    const cc_login = async function(){
        await $patch('/api/protected/agent/status', {status:"Available"});
    }
    const cc_logout = async function(){
        await $patch('/api/protected/agent/status', {status:"Logged Out"});
    }

</script>